/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://b5ln7wppjrafnactoldjfldtnq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-hegemh3cijfabb7jq4mdumaosq",
    "aws_cloud_logic_custom": [
        {
            "name": "dataapi",
            "endpoint": "https://lieskrzqy4.execute-api.us-west-2.amazonaws.com/test",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:affe3ccb-686e-4316-ac26-c30fead0b590",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_heMAaBlT6",
    "aws_user_pools_web_client_id": "7crnjr94lm81opsfklgnchfust",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "fight-chance-ai-storage-73dbd8a830240-test",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
